<template>
  <div
    class="class4-flammable-solids px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Tests for Class 4 (Flammable Solids)" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Class 4.1: Is the substance or article a Flammable Solid or Self-Reactive Substance?"
        />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Screening Test
          </h4>
          <p>
            For the preliminary screening test, an unbroken strip or powder
            train about 250 mm long by 20 mm at the base and 10 mm high is
            placed on a low heat-conducting plate. A gas flame (burner torch) is
            used to ignite the powder train at one end (minimum temperature
            1000°C). If the material does not ignite within two minutes or takes
            longer than two minutes to burn 200 mm of the powder train, it is
            not considered a flammable solid. If the material ignites and
            propagates by either flame or smoldering through a 200 mm length in
            less than two minutes, then the material is considered to be a
            flammable solid and the burn rate tests are conducted.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Burning Rate Test
          </h4>
          <p>
            The material is filled into a 250 mm long mould with a triangular
            cross-section of inner height 10 mm and width 20 mm. The mould is
            inverted onto a plate and ignited with a small flame or hot wire.
            The burn rate is timed for 100 mm after the pile has been allowed to
            burn a distance of 80 mm. Approximately 30 to 40 mm beyond the burn
            rate timing zone, a wetting solution is applied to the pile in order
            to determine if the flame propagates through this area in 4 minutes
            or less. Based on the test results, Division and Packing Group
            classifications are assigned.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Tests for Self-Reactive Substances
          </h4>
          <p>
            Substances that are related to the self-reactive substances of
            Division 4.1 are distinguished from self reactive substances by the
            fact that the former have a self-accelerating decomposition
            temperature for a 50-kg package greater than 75 °C. The test
            determines if a material will explode or detonate when ignited under
            confinement. The sample is loaded into a pipe with 3000 pound
            pressure tested forged steel end caps. A 10-gram black powder bag
            igniter is inserted into the center of the pipe, the pipe is filled
            with test material and the ends capped. After the igniter is fired,
            if either the pipe or at least one of the end caps is fragmented
            into two or more distinct pieces then the test result is positive.
            The test is considered negative (the material passes) if the pipe is
            merely split open or the caps are sheared off in one piece. Three
            trials are performed unless a transition from deflagration to
            explosion occurs earlier.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Class 4.2: Is the substance or article a Spontaneously Combustible material?"
        />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Self-heating Substances Test
          </h4>
          <p>
            This test is performed to determine if the material in a 25 mm or
            100 mm sample cube at temperatures of 100 °C, 120 °C, and 140 °C
            will undergo spontaneous ignition or dangerous self-heating
          </p>
        </Paragraph>
        <TitleComponent
          title="Class 4.3: Is the substance or article a Dangerous When Wet material?"
        />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Hazardous When Wet Test
          </h4>
          <p>
            This is a series of four tests beginning with a very small sample
            and gradually increasing in size up to 25 grams. The sample material
            is exposed to varying degrees of moisture and the reaction or gas
            evolution is observed and measured. Wetted materials that do not
            spontaneously ignite nor release more than 1 liter of flammable or
            toxic gas per hour per kilogram of material are not considered
            Dangerous When Wet.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Class 4 Flammable Solid Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "To determine what subclassification of Class 4 (flammable solid) a substance or article is, it must undergo a series of tests. These tests include a Screening Test, a Burning Rate Test, Tests for Self-Reactive Substances, Self-heating substances Test, and Hazardous When Wet Test."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.class4-flammable-solids {
  h4 {
    font-size: 13px;
    color: black;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
  }
}
</style>
